
import { Component, Mixins } from "vue-property-decorator";
import { Image, Row, List, NavBar } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import PerHead from "@/views/TicketOrder/PerOrderDetails/components/PerHead.vue";
import FooterLine from "@/views/Mall/MallPage/FooterLine.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [Row.name]: Row,
    [List.name]: List,
    PerHead,
    FooterLine,
  },
})
export default class ChannelDistributionCustomPage extends Mixins(Mixin) {
  headImageUrl = ""; // 头图
  backgroundColor = ""; // 背景色
  navTitle = "保利票务"; // 标题
  projects: Array<good.RecommendProductDto> = [];
  currentPage = 1; // 滚动分页，当前在第几页
  pageFinished = false; // 滚动分页，是否已经没有数据了
  loading = false;

  mounted(): void {
    let distributionChannelId = this.$route.query.distributionChannelId;
    let distributionSeriesId = this.$route.query.distributionSeriesId;
    if (distributionChannelId && distributionSeriesId) {
      this.$api.cmsApi.commonInfo.getDistributionTheme(
        String(distributionChannelId),
        String(distributionSeriesId),
        ({ data }) => {
          this.headImageUrl = data.image || "";
          this.backgroundColor = data.backColor || "";
          let name = data.name;
          if (name) {
            window.document.title = name;
            this.navTitle = name;
          }
        }
      );
    }
  }

  onLoad(): void {
    let distributionChannelId = this.$route.query.distributionChannelId;
    let distributionSeriesId = this.$route.query.distributionSeriesId;
    if (distributionChannelId && distributionSeriesId) {
      this.loading = true;
      let currentPage = this.currentPage;
      this.$api.goodApi.product.searchDistributionProducts(
        {
          distributionId: String(distributionChannelId),
          seriesId: String(distributionSeriesId),
          source: "custom",
          page: currentPage,
          size: 20,
        },
        ({ data }) => {
          this.loading = false;
          if (data.records) {
            this.projects = (this.projects || []).concat(data.records);
          }
          this.setPageFinished(currentPage, data);
        }
      );
    }
  }

  // 滚动分页，根据返回的数据设置【是否查询完成】的标志
  setPageFinished(currentPage: number, data: good.Page<unknown>): void {
    let pages = 0;
    // 终止加载的标志
    if (data) {
      pages = data.pages || 0;
    }
    this.pageFinished = currentPage >= pages;
    this.currentPage = currentPage + 1;
  }

  /*
   * 去抢购详情
   */
  goShowDetail(item: good.RecommendProductDto): void {
    this.goToProjectDetail(item.productId, item.tourId);
  }
}
